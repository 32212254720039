<style lang="less" scoped>
  .choose-user {
    max-height: 350px;
    display: flex;
    flex-direction: column;
  }
  .user-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .user-group-item {
    padding: 0px 10px;
    margin-right: 20px;
    line-height: 20px;
    border-radius: 5px;
    color: #515a6e;
    border: 1px solid #515a6e;
    cursor: pointer;
  }
  .user-group-item-active {
    color: #F4628F;
    border-color: #F4628F;
  }
  .choosed {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    .name-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .n-item {
        padding: 0 10px;
        margin-right: 10px;
      }
    }
  }
  .user-group-item:hover {
    border-color: #F4628F;
  }
</style>

<template>
  <div class="choose-user">
    <div class="choosed" v-if="multiple">
      <div>已选人员：</div>
      <div class="name-list">
        <fm-checkbox-group wrap v-model="choosedData" @change="choosedDataChange">
          <fm-checkbox style="margin-right: 20px;" :label="item.key" :key="item.key" :value="item.key" v-for="item in selectUserList.filter(v => data && data.includes(v.key))">{{item.label}}</fm-checkbox>
        </fm-checkbox-group>
      </div>
    </div>
    <div class="user-group" v-if="multiple">
      <div class="user-group-item" :class="{'user-group-item-active': item.active}" @click="clickGroup(item)" v-for="item in userGroup" :key="item.id">
        {{item.name}}
      </div>
    </div>
    <fm-input-new placeholder="请输入姓名搜索" v-model="searchKey"></fm-input-new>
    <div style="flex: 1;overflow: auto;">
      <template v-if="multiple">
        <fm-checkbox-group wrap v-model="data" @change="$emit('input', data)">
          <fm-checkbox style="min-width: 90px;margin-bottom: 5px;margin-top: 5px;margin-right: 20px;" :label="item.key" :key="item.key" :value="item.key" v-for="item in selectUserList">{{item.label}}</fm-checkbox>
        </fm-checkbox-group>
      </template>
      <template v-else>
        <fm-radio-group style="margin-top: 10px;" vertical v-model="data" @change="$emit('input', data)">
          <fm-radio :label="String(item.label)" :key="item.key" :value="item.key" v-for="item in selectUserList"></fm-radio>
        </fm-radio-group>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserChoose',
  props: {
    value: { type: [Array, Number, String], default: () => null },
    multiple: { type: Boolean, default: true },
    userList: Array
  },
  data () {
    return {
      searchKey: null,
      data: this.value,
      choosedData: []
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.data = this.value
      }
    },
    data: {
      deep: true,
      handler () {
        this.choosedData = this.data ? [...this.data] : []
      },
      immediate: true
    }
  },
  computed: {
    selectUserList () {
      if (this.searchKey) {
        return this.userList.filter(v => v.label.indexOf(this.searchKey) > -1)
      } else {
        return this.userList
      }
    },
    userGroup () {
      let data = this.$store.getters.userGroup.map(v => {
        let ids = v.value ? v.value.split(',').map(v => Number(v)) : []
        let item = Object.assign({}, v, {
          ids,
          active: ids.length > 0 && ids.filter(v1 => this.choosedData.includes(v1)).length == ids.length
        })
        return item
      })
      return data
    }
  },
  methods: {
    choosedDataChange () {
      this.data = this.choosedData ? [...this.choosedData] : []
    },
    clickGroup (group) {
      if (group.active) {
        this.data = this.data.filter(v => !group.ids.includes(v))
      } else {
        this.data = this.data.filter(v => !group.ids.includes(v)).concat(group.ids)
      }
    }
  }
}
</script>
