<template>
  <fm-modal :mask-closable="false" v-model="modal" width="1000px" v-if="modal">
    <fm-title title-text="处理明细"></fm-title>
    <fm-table-new
      :simple-filter="true"
      :columns="columns"
      border="row"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
    </fm-table-new>
    <fm-form-dialog
    form-title="处理时间修改"
    :open-dialog.sync="openDialog"
    :form-parms="formParms"
    :old-data="choose"
    :mask-closable="false"
    form-width="600px"
    @formSubmit="formSubmit"
    @handleClose="openDialog = false">
  </fm-form-dialog>
  </fm-modal>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import {
  officialDocumentHandleRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {TableActions},
  data () {
    return {
      choose: null,
      modal: false,
      openDialog: false,
      dataList: [],
      docId: null
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'datePicker',
        label: '处理时间',
        key: 'handleTime',
        check: {
          required: true
        }
      }]
    },
    columns () {
      return [{
        title: '环节',
        sort: true,
        field: 'officialDocumentHandleStepType',
      },
      {
        title: '处理人',
        sort: true,
        field: 'userName',
      },
      {
        title: '处理状态',
        sort: true,
        field: 'statusText',
      },
      {
        title: '处理内容',
        sort: true,
        field: 'content'
      },
      {
        title: '处理时间',
        sort: true,
        field: 'handleTime',
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.handleTime ? rowData.handleTime.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        slot: 'actions',
        search: false,
        export: false,
        configurable: false
      }]
    },
    tableActions () {
      return [{
        key: 'updateHandleTime',
        label: '修改处理时间',
        show (data) {
          return data.status === 'over'
        }
      }]
    }
  },
  methods: {
    tableAction (parm) {
      this.choose = parm.data
      if (parm.action === 'updateHandleTime') {
        this.openDialog = true
      }
    },
    open(id) {
      this.docId = id
      this.loadData()
      this.modal = true
    },
    async loadData () {
      let datas = await officialDocumentHandleRequest.get({
        officialDocumentId: this.docId
      })
      datas.forEach(v => {
        v.statusText = v.status === 'over' ? '已处理' : '未处理'
      })
      this.dataList = datas
    },
    async formSubmit (data, resolve) {
      let parm = {
        handleTime: dateOperating.computeDay({days: 0, date: data.handleTime, format: 'yy-mm-dd hh:mm:ss'})
      }
      await officialDocumentHandleRequest.updateHandleTime(this.choose.id, parm)
      resolve()
      this.openDialog = false
      this.loadData()
    },
  }
}
</script>