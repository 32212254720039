<template>
  <sign-page
    :title-text="typeMap[type] || '公文管理'"
    :request="request"
    table-size="large"
    simpleFilter="head"
    :title-menus="[{key: 'out_put', label: '导出'}, {key: 'out_put_by_level', label: '导出目录'}, {key: 'new', label: '新增'}]"
    :tableActions="tableActions"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :column-list="columnList"
    ref="docPage"
    :table-actions-fixed="true"
    :tableActionsWidth="330"
    :need-data-file="true">
    <fm-modal v-model="modal" v-if="modal" width="1100px" :mask-closable="false">
      <doc-form v-if="modal && type !== 'qtwj'" :type="type" :id="chooseData ? chooseData.id : null" @dataUpdate="$refs.docPage.loadData()" @close="modal = false" :workerUserList="$store.getters.workerUserList"></doc-form>
      <qtwj-form v-if="modal && type === 'qtwj'" :type="type" :id="chooseData ? chooseData.id : null" @dataUpdate="$refs.docPage.loadData()" @close="modal = false" :workerUserList="$store.getters.workerUserList"></qtwj-form>
    </fm-modal>
    <a4-print ref="print" :mask-closable="false"/>
    <fm-modal :title="'操作记录：' + chooseData.title" v-model="modalLog" v-if="modalLog" width="800px" :mask-closable="false">
      <div class="log-list">
        <div v-for="(item, index) in logList" class="log-item" :key="index">
          <div class="item-c" style="min-width: 160px;">{{item.time}}</div>
          <div class="item-c" style="min-width: 80px;">{{item.userName}}</div>
          <div class="item-c" style="min-width: 90px;">{{item.actionLabel}}</div>
          <div class="item-c">
            <div class="c-item" v-for="(c, i) in item.content" :key="i">
              {{c}}
            </div>
          </div>
        </div>
      </div>
      <div class="no-log" v-if="logList.length === 0">
        暂无操作记录
      </div>
    </fm-modal>
    <template v-slot:title>
      <div style="display: flex;align-items: center;margin-left: 20px;">
        <fm-date-picker v-model="year" @change="yearChange" :clearable="true" type="year" placement="bottom-end" placeholder="请选择年份" style="width: 200px" />
      </div>
    </template>
    <HandleList ref="handleList"></HandleList>
  </sign-page>
</template>

<script>
import DocForm from './cmp/form'
import QtwjForm from './cmp/formQtwj'
import HandleList from './cmp/handleList'
import DocTitle from './cmp/docTitle'
import A4Print from '@/components/a4print'
import sysEnv from '@/env/env'
import {
  fileHepler
} from '@/fmlib'

import {
  officialDocumentRequest as request
} from '../../api'

import {
  dealLog
} from './log_lib'

import XLSX from 'xlsx'

import { fileRequest } from '@/api'

let signFileLoading = {}
async function getSignFile (id) {
  if (signFileLoading[id]) {
    return signFileLoading[id]
  }
  return signFileLoading[id] = fileRequest.download({ id })
}

let userSignFiles = {}
async function loadSignFile (id) {
  if (!userSignFiles[id]) {
    const file = await getSignFile(id)
    userSignFiles[id] = window.URL.createObjectURL(new Blob([file]))
  }
  return userSignFiles[id]
}

export default {
  components: {
    DocForm, A4Print, QtwjForm, HandleList
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
    this.$store.dispatch('loadUserGroup')
  },
  props: {
    type: {
      type: String,
      default: 'doc'
    }
  },
  methods: {
    clickTitleMenu (key) {
      if (key === 'new') {
        this.chooseData = null
        this.modal = true
      } else {
        let data = this.$refs.docPage.dataList.map(v => {
          return Object.assign({}, v, {
            sf: '收',
            getTimeS: v.getTime ? v.getTime.slice(0, 10) : ''
          })
        })
        if (key === 'out_put') {
          fileHepler.outPutTableData(data, this.outPutColumnList, (sysEnv.title || '') + (this.year ? this.year.getFullYear() + '年' : '') + '公文收文登记表.xlsx')
        } else {
          this.exportByLevel(data)
        }
      }
    },
    exportByLevel (data) {
      const outPutColumnList = [
        { title: '顺序号', field: (v, i) => i + 1 },
        { title: '文件作者', field: 'sendOrgName' },
        { title: '文件上原编字号', field: 'sendCode' },
        { title: '文件日期', field: 'sendTime' },
        { title: '标题', field: 'title' },
        { title: '文件所在账号', field: 'account' },
        { title: '备考', field: 'getCode' }
      ]
      const workbook = XLSX.utils.book_new()
      console.log(data)
      Array.from(new Set(data.map(v => v.sendLevel))).forEach(sendLevel => {
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet([
          outPutColumnList.map(v => v.title),
          ...data.filter(v => v.sendLevel === sendLevel).map((item, index) => {
            return outPutColumnList.map(v => {
              if (typeof v.field === 'string') {
                return item[v.field]
              } else {
                return v.field(item, index)
              }
            })
          })
        ]), sendLevel || '其他')
      })
      XLSX.writeFile(workbook, (sysEnv.title || '') + (this.year ? this.year.getFullYear() + '年' : '') + '公文收文登记表.xlsx')
    },
    yearChange () {
      this.$refs.docPage.loadData()
    },
    async loadData (parm) {
      parm = parm || {}
      parm.type = this.type
      if (this.year) {
        parm.getYear = this.year.getFullYear()
      } else {
        parm.getYear = undefined
      }
      let data = await request.get(parm)
      data.forEach(v => v.statusText = v.status === 'doing' ? '未结束' : '已结束')
      return data
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'edit_all') {
        this.modal = true
      } else if (action === 'over') {
        let datas = await request.getDetail({
          officialDocumentId: this.chooseData.id,
          type: this.type
        })
        if (datas.length === 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员。'
          })
          return
        }
        let waitDeal = []
        datas[0].steps.forEach(step => {
          step.handles.forEach(item => {
            if (item.status !== 'over' && !waitDeal.includes(item.userName)) {
              waitDeal.push(item.userName)
            }
          })
        })
        if (waitDeal.length !== 0) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '该公文还有 ' + waitDeal.join('，') + ' 未处理，确定要继续归档吗?'})
          if (!result) {
            return
          }
        }
        this.chooseData.status = 'over'
        await request.over(this.chooseData.id)
        this.$notice.success({
          title: '系统提示',
          desc: '公文已结束。'
        })
        this.$refs.docPage.loadData()
      } else if (action === 'detail') {
        this.$router.push({
          name: 'hrms.doc.detail',
          query: {
            id: this.chooseData.id,
            type: this.type
          }
        })
      } else if (action === 'back') {
        await request.back(this.chooseData.id)
        this.$notice.success({
          title: '系统提示',
          desc: '公文可继续处理。'
        })
        this.$refs.docPage.loadData()
      } else if (action === 'cover') {
        this.printCover()
      } else if (action === 'log') {
        this.loadLog()
        this.modalLog = true
      } else if (action === 'dealList') {
        this.$refs.handleList.open(this.chooseData.id)
      }
    },
    async loadLog () {
      let parm = {
        officialDocumentId: this.chooseData.id
      }
      let datas = await request.getLog(parm)
      this.logList = dealLog(datas, this.$store.getters.workerUserList)
    },
    async printCover () {
      const res = await request.getDetail({
        officialDocumentId: this.chooseData.id
      })
      if (res.length) {
        this.$refs.print.print('doc-cover', '来文处理标签', async (tpl) => {
          const steps = res[0].steps

          const fileIds = Array.from(new Set(steps.map(v => v.handles.filter(l => l.status === 'over' && l.handleUserSignFileId).map(l => l.handleUserSignFileId)).flat()))
          await Promise.all(fileIds.map(id => loadSignFile(id)))

          const html = steps.map(({ type, handles }) => {
            const content = handles.filter(v => v.status === 'over').map(item => {
              let sign = !item.handleUserSignFileId || !userSignFiles[item.handleUserSignFileId] ? item.handleUserName : ('<img src="' + userSignFiles[item.handleUserSignFileId] + '" style="height: 30px;" />')
              return (item.content ? (item.content + ' ') : '') + sign + ' ' + (item.handleTime ? item.handleTime.slice(0, 10) : '')
            }).join('<br />')
            return '<div class="row">'
              + '<div class="type">' + type + '</div>'
              + '<div class="text" style="display: flex;align-items: center;">' + content + '</div>'
            + '</div>'
          }).join('')
          return tpl.replace('<!--html-->', html).replace('<!--title-->', (sysEnv.title || '未配置单位名称').split('').join('&nbsp;'))
        })
      }
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'edit_all',
        label: '编辑',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'detail',
        label: '查看'
      },
      {
        key: 'back',
        label: '继续处理',
        show (data) {
          return data.status === 'over'
        }
      },
      {
        key: 'cover',
        label: '打印封面',
        show (data) {
          return data.status === 'over'
        }
      },
      {
        key: 'over',
        label: '归档',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'del',
        label: '删除',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'dealList',
        label: '处理明细',
        show (data) {
          return data.status === 'over' || data.status === 'doing'
        }
      },
      {
        key: 'log',
        label: '操作记录'
      }]
      if (this.type === 'qtwj') {
        data = data.filter(v => v.key !== 'cover')
      }
      return data
    },
    outPutColumnList () {
      return [{
        title: '编码',
        field: 'code'
      },
      {
        title: '文件名称',
        field: 'title'
      },
      {
        title: '收/发',
        field: 'sf'
      },
      {
        title: '来文单位',
        field: 'sendOrgName'
      },
      {
        title: '文号',
        field: 'sendCode'
      },
      {
        title: '收文时间',
        field: 'getTimeS'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编码',
          sort: true,
          field: 'code'
        },
        {
          title: '文件名称',
          sort: true,
          field: 'title',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.title
              }
            })
          }
        },
        {
          title: '备注',
          sort: true,
          field: 'remark',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.remark,
                maxWidth: 270
              }
            })
          }
        },
        {
          title: '来文级别',
          sort: true,
          field: 'sendLevel'
        },
        {
          title: '来文单位',
          sort: true,
          field: 'sendOrgName'
        },
        {
          title: '来文份数',
          sort: true,
          field: 'num',
          dataType: Number
        },
        {
          title: '来文日期',
          sort: true,
          field: 'sendTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.sendTime ? rowData.sendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '来文号',
          sort: true,
          field: 'sendCode'
        },
        {
          title: '收文日期',
          sort: true,
          field: 'getTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getTime ? rowData.getTime.slice(0, 10) : '-')
          }
        },
        {
          title: '归入案卷号',
          sort: true,
          field: 'getCode',
          render: (h, rowData) => {
            return h('div', rowData && rowData.getCode ? rowData.getCode : rowData.id)
          }
        },
        {
          title: '状态',
          sort: true,
          field: 'statusText'
        }]
        if (this.type === 'hywj') {
          data = data.filter(v => v.field !== 'getCode')
        } else if (this.type === 'qtwj') {
          data = data.filter(v => ['code', 'title', 'getTime'].includes(v.field))
        }
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      chooseData: null,
      modal: false,
      modalLog: false,
      year: new Date(),
      logList: [],
      typeMap: {
        doc: '公文管理',
        hywj: '会议与培训文件管理',
        qtwj: '其他文件管理'
      }
    }
  }
}
</script>

<style scoped lang="less">
.no-log {
  width: 100%;
  text-align: center;
  line-height: 10rem;
  font-size: 1.5rem;
}
.log-list {
  .log-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    background: rgba(0,0,0,0.05);
    padding: 10px;
    border-radius: 5px;
  }
  .item-c {
    padding-right: 2rem;
  }
}
</style>