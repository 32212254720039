<style>
  .print-preview {
    background-color: #EEE;
    min-height: 70vh;
  }
  .print-preview iframe {
      border: none;
      box-shadow: 0 0 5px hsl(0deg 0% 0% / 10%);
      display: block;
      background-color: #FFF;
      width: 21cm;
      height: 70vh;
      margin: 10px auto;
      padding: 0.254cm 0.27cm 0.254cm 0.27cm;
      box-sizing: border-box;
  }
</style>

<template>
  <fm-modal v-model="show" :title="title" width="30cm" :mask-closable="maskClosable">
    <div class="print-preview" v-loadingx="loading.load" ref="wrap"></div>
    <div slot="footer" class="modal-footer-btns" :mask-closable="false">
      <fm-btn :disabled="loading.load" @click="$iframe.contentWindow.print()">打印</fm-btn>
      <fm-btn :disabled="loading.load" @click="show = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>

const tplMap = Object.freeze({
  contract: { path: '/static/document/contract.html' },
  resume: { path: '/static/document/resume.html' },
  'doc-cover': { path: '/static/document/doc-cover.html' }
});

// 解决同时触发大量图片加载的并发问题
async function batch_loading (imgs, num) {
  let loading = []
  for (let i = 0, l = num; i < imgs.length && l > 0; i++) {
    if (imgs[i].getAttribute('lazy-src')) {
      loading.push((function (img) {
        return new Promise(resolve => {
          img.addEventListener('load', () => resolve())
          img.addEventListener('error', () => resolve())
          img.src = imgs[i].getAttribute('lazy-src')
          imgs[i].removeAttribute('lazy-src')
        })
      })(imgs[i]))
      l--
    }
  }
  if (loading.length) {
    await Promise.all(loading)
    if (imgs.length > loading.length) {
      batch_loading(imgs.slice(loading.length), num)
    }
  }
}

export default {
  props: {
    maskClosable: { type: Boolean, default: true }
  },
  data () {
    return {
      $iframe: null,
      show: false,
      title: null,
      loading: {
        load: false
      }
    }
  },
  methods: {
    print (type, title, fn) {
      if (this.$iframe) {
        this.$iframe.remove()
      }

      this.title = title
      this.show = true
      this.loading.load = true

      this.$iframe = document.createElement('iframe')
      this.$iframe.src = tplMap[type].path
      this.$iframe.addEventListener('load', async () => {
        const tpl = fn(this.$iframe.contentDocument.body.innerHTML)
        if (tpl instanceof Promise) {
          this.$iframe.contentDocument.body.innerHTML = await tpl
        } else {
          this.$iframe.contentDocument.body.innerHTML = tpl
        }
        const imgs = this.$iframe.contentDocument.body.getElementsByTagName('img')
        const load = []
        for (let i = 0; i < imgs.length; i++) {
          if (imgs[i].src && imgs[i].complete) {
            load.push(Promise.resolve())
          } else {
            load.push((function (img) {
              return new Promise(resolve => {
                img.addEventListener('load', () => resolve())
                img.addEventListener('error', () => resolve())
              })
            })(imgs[i]))
          }
        }
        console.log(load)
        batch_loading(Array.from(this.$iframe.contentDocument.body.querySelectorAll('img[lazy-src]')), 2)
        await Promise.all(load)
        this.loading.load = false
      });

      this.$refs.wrap.appendChild(this.$iframe)
    }
  }
}
</script>
