export const actions = [{
  key: 'add',
  label: '新增公文',
  isStart: true
// },
// {
//   key: 'add_step',
//   label: '新增步骤',
//   isStart: false
// },
// {
//   key: 'add_handle',
//   label: '添加处理人',
//   isStart: false
},
{
  key: 'update',
  label: '修改公文',
  isStart: true
},
// {
//   key: 'update_step',
//   label: '修改步骤',
//   isStart: false
// },
// {
//   key: 'update_handle',
//   label: '修改处理人',
//   isStart: false
// },
{
  key: 'del',
  label: '删除公文',
  isStart: true
},
// {
//   key: 'del_step',
//   label: '删除步骤',
//   isStart: false
// },
// {
//   key: 'del_handle',
//   label: '删除处理人',
//   isStart: false
// },
{
  key: 'status_to_over',
  label: '归档',
  isStart: true
},
{
  key: 'status_to_doing',
  label: '继续处理',
  isStart: true
},
{
  key: 'handle',
  label: '处理',
  isStart: true
}]

export const dealLog = (datas, userList) => {
  let logList = []
  let i = 0
  while (i < datas.length) {
    let item = datas[i]
    let action = actions.find(v => v.key === item.action)
    if (!action) {
      i += 1
      continue
    }
    if (action.key === 'add') {
      logList.push(dealAddLog(item, userList))
    } else if (action.key === 'update') {
      logList.push(dealUpdateLog(item, userList))
    } else {
      logList.push({
        time: item.createTime,
        actionLabel: action.label,
        userName: item.userName,
        content: action.key === 'handle' && item.data ? [JSON.parse(item.data).content] : []
      })
    }
    i += 1
  }
  logList = logList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
  return logList
}

export const dealAddLog = (addLog, userList) => {
  let action = actions.find(v => v.key === 'add')
  let log = {
    time: addLog.createTime,
    actionLabel: action.label,
    userName: addLog.userName,
    content: []
  }
  let data = addLog.data ? JSON.parse(addLog.data) : {}
  let stepList = data.stepList || []
  if (stepList.length === 0) {
    log.content.push('未指定步骤')
  } else {
    stepList.forEach(v => {
      let s = '新增步骤：' + v.type
      if (v.handleList && v.handleList.length > 0) {
        let userNams = v.handleList.map(a => userList.find(u => u.key === a.userId)).filter(a => a).map(a => a.label).join('、')
        s += '，指定处理人：' + userNams
      }
      log.content.push(s)
    })
  }
  return log
}

export const dealUpdateLog = (addLog, userList) => {
  let action = actions.find(v => v.key === 'update')
  let log = {
    time: addLog.createTime,
    actionLabel: action.label,
    userName: addLog.userName,
    content: []
  }
  let data = addLog.data ? JSON.parse(addLog.data) : {}
  let stepList = data.stepList || []
  stepList.forEach(v => {
    let s = ''
    if (!v.id) {
      s = '新增步骤：' + v.type
      if (v.handleList && v.handleList.length > 0) {
        let userNams = v.handleList.map(a => userList.find(u => u.key === a.userId)).filter(a => a).map(a => a.label).join('、')
        s += '，指定处理人:' + userNams
      }
    } else if (v.isDel) {
      s = '删除步骤：' + v.type
    } else {
      if (v.handleList && v.handleList.length > 0) {
        let addHandleList = v.handleList.filter(v1 => !v1.id && !v1.isDel)
        let delHandleList = v.handleList.filter(v1 => v1.id && v1.isDel)
        if (addHandleList.length > 0 || delHandleList.length > 0) {
          s = '调整' + v.type + '处理人'
          let addUserNams = addHandleList.map(a => userList.find(u => u.key === a.userId)).filter(a => a).map(a => a.label).join('、')
          let delUserNams = delHandleList.map(a => userList.find(u => u.key === a.userId)).filter(a => a).map(a => a.label).join('、')
          if (addUserNams) {
            s += '，添加：' + addUserNams
          }
          if (delUserNams) {
            s += '，删除：' + delUserNams
          }
        }
      }
    }
    if (s) {
      log.content.push(s)
    }
  })
  return log
}